import {ErrorBoundary} from 'react-error-boundary'
import {Outlet} from 'react-router-dom'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import ErrorFallback from '../ErrorFallback'
import {Suspense} from 'react'
import {TDXCircularProgress} from '../UI'
import {ScrollToTop} from '../ScrollToTop'
import {SnackBarProvider} from '../../app/providers/SnackbarProvider'
import {useAppSelector} from '../../shared/hooks/hooks'
import {Maintenance} from '../Maintenance'

export const Layout = () => {
    const isLoggedIn = useAppSelector(state => state.Auth.isLoggedIn)
    const {settings} = useAppSelector(state => state.General.common)

    return settings?.maintenance?.enabled?.value ? (
        <Maintenance />
    ): (
        <SnackBarProvider>
            {isLoggedIn && <Header />}
            <main>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<TDXCircularProgress />}>
                        <Outlet />
                    </Suspense>
                </ErrorBoundary>
            </main>
            <Footer />
            <ScrollToTop />
        </SnackBarProvider>
    )
}
