//import BlackFridayPopup from "./BlackFridayPopup";
import TDXPopup from "./TDXPopup";
import PollPopup from "./PollPopup";
import {useAppDispatch, useAppSelector} from "../../../shared/hooks/hooks";
import {useEffect} from "react";
import {getPolls, getPopups} from '../../../store/actions/GeneralActions';
import {namedRequestsInProgress} from '../../../store/RequestSelectors'
import {GeneralRequestsEnum} from '../../../api/general.service'

const Popups = () => {
    const dispatch = useAppDispatch()
    const isLoggedIn = useAppSelector(state => state.Auth.isLoggedIn)
    const {polls, popups, isLoading} = useAppSelector(state => ({
        ...state.General.common,
        isLoading: namedRequestsInProgress(state, GeneralRequestsEnum.getPolls),
    }))

    useEffect(() => {
        if (isLoggedIn && !isLoading && polls.length === 0) {
            dispatch<any>(getPopups())
            dispatch<any>(getPolls())
        }
    }, [isLoggedIn])

    return (
        <>
            {/*{duty_manager?.data?.task_1042377?.need_black_friday*/}
            {/*? <BlackFridayPopup />*/}
            {/*: ''}*/}

            {popups?.length > 0 ? (
                <>
                    {popups.map(popup => (
                        <TDXPopup key={popup.id} popup={popup}/>
                    ))}
                </>
            ): ''}

            {polls?.length > 0 ? (
                <>
                    {polls.filter(p => p.is_popup).map(poll => (
                        <PollPopup poll={poll} key={poll.id} isOpenPopup={false} onClose={() => {}} />
                    ))}
                </>
            ): ''}

        </>
    )
}

export default Popups
