import axios from 'axios'
import {isBlobResponse} from '../utils/isBlob'

export type ApiScheme = 'http' | 'https';

function configGet () : {
    API_HOST : string,
    API_SCHEME : ApiScheme,
    FRONTEND_VERSION: string,
    API_BASEURL: string,
    API_VERSION: string,
} {
    const c2 = window as any;
    const c = (c2.__RUNTIME_CONFIG__) as Record<string, string>;

    const API_HOST = c?.['API_URL'] || 'api.b2b.tdx.by/'
    const API_SCHEME = (c?.['API_SCHEME'] || 'https') as ApiScheme;
    const FRONTEND_VERSION = c.FRONTEND_VERSION
    const API_VERSION = 'api/v1'
    const API_BASEURL = (
        c?.['API_BASEURL'] ||
        API_SCHEME + '://' + API_HOST
    ) + API_VERSION

    return {
        API_HOST, API_SCHEME, FRONTEND_VERSION, API_VERSION, API_BASEURL
    }
}

const API_HOST = configGet().API_HOST;
const API_SCHEME = configGet().API_SCHEME;
const FRONTEND_VERSION = configGet().FRONTEND_VERSION;
const API_VERSION = configGet().API_VERSION;
const API_BASEURL = configGet().API_BASEURL;

const ApiInstance = axios.create({
    baseURL: API_BASEURL,
    headers: {
        'Content-Type': 'application/json'
    },
})

export function getHeaders (url : string) : Record<string, string> {
    return {
        'Custom-Referer': getReferrer(url),
        'Frontend-Version': FRONTEND_VERSION,
    }
}

export const get = (url : string, config : any = {}) => ApiInstance.get(url, {
...config, headers: getHeaders(url)
}).then(res => {
    //console.log('get');
    //console.log('config', res);
    return res.data;
})

export const post = (
    url : string,
    body : any=undefined,
        config : any = {}
) => ApiInstance.post(url, body, {
    headers: getHeaders(url),
    ...(config !== undefined ? config : {}),
}).then(res => res.data)

export const getFileAsBlob = (url : string, params : any = null) =>
    ApiInstance.get(url, {
        params,
        responseType: 'blob',
        validateStatus: status => (status >= 200 && status <= 300) || status === 503 || status === 404,
    }).then(async res => {
        if (!isBlobResponse(res.data)) {
            return {
                data: JSON.parse(await res.data.text()),
                status: res.status,
            }
        }

        let content_disposition = ''
        let filename = ''
        let message = ''

        try {
            content_disposition = decodeURIComponent(atob(res.headers['content-disposition']
                .replace('=?utf-8?b?', '')
                .replace('=', '')
                .replace('?', '')
                .replace(/-/g, "+")
                .replace(/_/g, "/"))
                .split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''))
        } catch (e) {
            //console.log(e)
            content_disposition = res.headers['content-disposition']
        }

        if (content_disposition.length > 0) {
            filename = content_disposition?.split('; ').filter((p:string) => p.includes('filename='))?.[0]?.split('filename=')?.[1]
            message = content_disposition?.split('; ').filter((p:string) => p.includes('message='))?.[0]?.split('message=')?.[1]
        }

        return {
            file: res.data,
            filename,
            message,
            status: res.status
        }
    }).catch(res => res)

const getReferrer = (
  url:any=undefined,
) => {
    let ls_referrer_url = localStorage.getItem('referrer_url')
    const ls_current_url = localStorage.getItem('current_url')
    const current_url = window.location.pathname

    if (current_url !== ls_current_url) {
        ls_referrer_url = ls_current_url
        localStorage.setItem('referrer_url', ls_referrer_url)
        localStorage.setItem('current_url', current_url)
    }

    return ls_referrer_url
}

export default ApiInstance
