export const REQUEST_STARTED = 'REQUEST_STARTED'
export const REQUEST_FINISHED = 'REQUEST_FINISHED'
export const REQUEST_FAILED = 'REQUEST_FAILED'

export const requestStarted = (requestName: any) => ({
    type: REQUEST_STARTED,
    request: {
        requestName,
        inProgress: true,
    },
})

export const requestFinished = (requestName: any) => ({
    type: REQUEST_FINISHED,
    request: {
        requestName,
        inProgress: false,
    },
})

export const requestFailed = ({requestName, error}: {requestName: any, error: any}) => ({
    type: REQUEST_FAILED,
    request: {
        requestName,
        inProgress: false,
        error,
    },
})
