import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import {useEffect, useState} from 'react'
import {/*TDXButton, */TDXNavLink} from '../UI'
import {Skeleton} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {getCategories} from "../../store/slice/catalogCategoriesSlice/services/getCategories/getCategories";
import {Category} from "../../store/slice/catalogCategoriesSlice/types";

const HeaderMobileMenuWrapper = ({targetMenu = undefined}) => {
    const dispatch = useAppDispatch()
    const {name, isManager, isSpecial, isCustomPrice, region, isMegaSklad, isElektronmir} = useAppSelector(
        state => state.Profile.profile
    )
    const hasRefusals = false
    const hasSupplies = false

    const [menu, setMenu] = useState([])

    const isLoading = useAppSelector(state => state.CatalogCategories.loading)
    //const categoriesLastFetch = useAppSelector(state => state.CatalogCategories.lastFetch)
    const categories = useAppSelector(state => state.CatalogCategories.categories)
    const currentCategory = useAppSelector(state => state.Catalog.category)
    const [activeCategory, setActiveCategory] = useState(null)
    const [depthLevel, setDepthLevel] = useState(0)
    const [menuHistory, setMenuHistory] = useState([])
    const [manualPage, setManualPage] = useState('/page/manual')

    useEffect(() => {
        if (isMegaSklad) {
            setManualPage('/page/manual-megasklad')
        } else if (isElektronmir) {
            setManualPage('/page/manual-elektronmir')
        }
    },[isMegaSklad, isElektronmir])

    const changeMenuItem = (item: Category) => {
        if (item?.subcategories && item?.subcategories.length > 0) {
            setActiveCategory(item)
            setMenuHistory([...menuHistory, item])
            setDepthLevel(depthLevel + 1)
        } else {
            targetMenu()
        }
    }

    const backMenu = () => {
        const menuHistory_tmp = menuHistory.slice(0, menuHistory.length - 1)
        setMenuHistory(menuHistory_tmp)

        if (depthLevel - 1 > 0) {
            setActiveCategory(menuHistory_tmp.slice(-1)[0])
        } else {
            setActiveCategory(null)
        }
        setDepthLevel(depthLevel - 1)
    }

    const prepareMenu = () => {
        let menu_tmp = []

        if (!isSpecial) {
            menu_tmp.push({url: '', name: 'Каталог', subcategories: categories})
        }

        menu_tmp.push({url: '/user/orders', name: 'Заказы', subcategories: []})

        menu_tmp.push({url: '/user/buffer', name: region === 'ru' ? 'Корзина': 'Буфер', subcategories: []})

        // if (!isSpecial) {
        //     menu_tmp.push({url: '/user/matching', name: 'Отчет', subcategories: []})
        // }

        menu_tmp.push({
            url: '/price-list',
            name: 'Запрос на прайс лист',
            subcategories: [],
            className: 'price',
        })
        if (hasRefusals) {
            menu_tmp.push({url: '/user/refusals', name: 'Отказы', subcategories: []})
        }

        menu_tmp.push({url: '/user/waiting-list', name: 'Лист ожидания', subcategories: []})

        if (hasSupplies && isManager) {
            menu_tmp.push({url: '/user/supplies', name: 'Поставки', subcategories: []})
        }

        if (!isMegaSklad && !isCustomPrice) {
            menu_tmp.push({url: '/page/delivery-schedule', name: 'График доставок', subcategories: []})
        }

        if (!isSpecial) {
            menu_tmp.push({
                url: '',
                name: 'FAQ',
                subcategories: [
                    {url: manualPage, name: 'Инструкция пользователя', subcategories: []},
                    {url: !isCustomPrice ? '/page/apidoc': '/page/apidoc-rf', name: 'Документация по API', subcategories: []},
                    {url: '/news', name: 'Новости компании', subcategories: []},
                ],
            })
        }

        if (isManager) {
            menu_tmp.push({
                url: '',
                name: 'Менеджер',
                subcategories: [
                    {
                        url: '/manager/users-settings',
                        name: 'Настройки пользователей',
                        subcategories: [],
                    },
                ],
            })
        }

        if (activeCategory !== null && activeCategory?.subcategories.length > 0) {
            menu_tmp = activeCategory?.subcategories
        }

        setMenu(menu_tmp)
    }

    useEffect(() => {
        prepareMenu()
    }, [name, isLoading, categories, activeCategory])

    return (
        <div className="menu-wrapper">
            {isLoading ? (
                <>
                    <Skeleton className="tdx-nav-link link" width={105} height={25} />
                    <Skeleton className="tdx-nav-link link" width={105} height={25} />
                    <Skeleton className="tdx-nav-link link" width={105} height={25} />
                    <Skeleton className="tdx-nav-link link" width={105} height={25} />
                    <Skeleton className="tdx-nav-link link" width={105} height={25} />
                </>
            ) : (
                <>
                    {depthLevel > 0 ? (
                        <div className="tdx-nav-link link backMenu" onClick={backMenu}>
                            {depthLevel > 0 ? <KeyboardArrowDownIcon /> : ''}
                            <p>Назад</p>
                        </div>
                    ) : (
                        ''
                    )}
                    {menu.map((item, key) => (
                        <div key={key}>
                            {item?.url?.length > 0 ? (
                                <TDXNavLink
                                    to={`${item.url}`}
                                    onClick={() => changeMenuItem(item)}
                                    title={`${item.name}`}
                                    className={`${item?.className}`}
                                />
                            ) : (
                                <>
                                    {depthLevel === 3 ? (
                                        currentCategory?.id === item.id ? (
                                            <span
                                                className={`tdx-nav-link link ${item?.className}`}
                                                onClick={() => targetMenu()}
                                            >{item.name}</span>
                                        ): (
                                            <TDXNavLink
                                                to={`/catalog/${item.id}`}
                                                title={`${item.name}`}
                                                className={`${item?.className}`}
                                                onClick={() => targetMenu()}
                                            />
                                        )
                                    ) : (
                                        <div
                                            className="tdx-nav-link link"
                                            onClick={() => changeMenuItem(item)}>
                                            <p>{item.name}</p>
                                            {item?.subcategories ? <KeyboardArrowDownIcon /> : ''}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ))}
                </>
            )}
        </div>
    )
}

export default HeaderMobileMenuWrapper
