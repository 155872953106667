const requestState = (state: any) => state['Request']

export const requestsInProgress = (state: any) =>
    requestState(state).requests.filter((request: any) => request.inProgress).length > 0

export const namedRequestsInProgress = (
    state: any,
    requestName: any // ServiceEnum | ServiceEnum[]
) => {
    const singleNamedRequestInProgress = (singleRequestName: any) =>
        requestState(state).requests.find(
            (request: any) => request.requestName === singleRequestName && request.inProgress
        ) !== undefined

    if (Array.isArray(requestName)) {
        return requestName.some(singleNamedRequestInProgress)
    }

    return singleNamedRequestInProgress(requestName)
}

export const namedRequestError = (state: any, requestName: any) =>
    requestState(state).requests.find(
        (request: any) => request.requestName === requestName && request.error !== null
    )?.error
