import {REQUEST_FAILED, REQUEST_FINISHED, REQUEST_STARTED} from '../actions/RequestActions'

const initialState = {
    requests: [], // {requestName, isPending, error}[]
} as {
    requests: any[]
}

export const RequestReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case REQUEST_STARTED: {
            const existingCall = state.requests.find(
                (request: any) => request.requestName === action.request.requestName
            )

            if (existingCall) {
                return {
                    ...state,
                    requests: state.requests.map((request: any) =>
                        request.requestName === action.request.requestName
                            ? {...request, inProgress: true, error: null}
                            : request
                    ),
                }
            }

            return {
                ...state,
                requests: [...state.requests, action.request],
            }
        }
        case REQUEST_FINISHED: {
            return {
                ...state,
                requests: state.requests.filter(
                    (request: any) => request.requestName !== action.request.requestName
                ),
            }
        }
        case REQUEST_FAILED: {
            return {
                ...state,
                requests: state.requests.map((request: any) =>
                    request.requestName === action.request.requestName
                        ? {
                              ...request,
                              error: action.request.error,
                              inProgress: false,
                          }
                        : request
                ),
            }
        }
        default: {
            return state
        }
    }
}
