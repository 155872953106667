import {Link} from 'react-router-dom'
import {FacebookIcon, TelegramIcon, LinkedinIcon, PhoneIcon, SkypeIcon, ViberIcon, } from '../Icons'
import {TDXButton} from '../UI'
import {ClickAwayListener, List, ListItem, Paper, Popper, Skeleton} from "@mui/material";
import UserIcon from "../Icons/UserIcon";
import {Logout} from "@mui/icons-material";
import MailIcon from "../Icons/MailIcon";
import {useState, useEffect, useCallback, useMemo} from 'react'
import {useModalWindow} from "../UI/TDXModalWindow";
import {authActions} from "../../store/slice/authSlice/authSlice";
import {useAppDispatch, useAppSelector} from "../../shared/hooks/hooks";
import TokenService from "../../api/token.service";
import Popups from "../UI/Popups/Popups";
import TDXLogo from "../UI/TDXLogo"
import utils_settings from "../../utils/settings";
import {Partner} from "../../store/slice/authSlice/types";

const currentYear = new Date().getFullYear()

const Footer = () => {
    const dispatch = useAppDispatch()

    const {/*isOpen,*/openWindow/*, closeWindow*/} = useModalWindow()
    const [menu, setMenu] = useState<Record<string, any>>({
        type: 'general',
        anchorEl: null,
    })
    const {name, isSpecial} = useAppSelector(state => state.Profile.profile)
    const isLoggedIn = useAppSelector(state => state.Auth.isLoggedIn)
    const partners = useAppSelector<Partner[]>(state => state.Auth.partners)
    const {settings} = useAppSelector(state => state.General.common)
    const partner = useMemo(() => partners.find(p => p.id === TokenService.getAccessPartnerId()) ?? {}, [partners]) as Partner
    const [current_manager, setCurrentManager] = useState<Partner['manager']>({})

    const targetMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement> | MouseEvent | TouchEvent, type = '') => {
        if (type.length>0) {
            e.preventDefault()
        }

        if (menu.anchorEl) {
            setMenu({...menu, anchorEl: null})
        } else {
            setMenu({type, anchorEl: e.currentTarget})
        }
    }

    const logOutHandler = useCallback(() => {
        dispatch(authActions.logout())
    }, [])

    useEffect(() => utils_settings.settings_init(settings, dispatch), [])

    useEffect(() => {
        const ui_setting = settings?.ui_settings as Record<string, any>;

        const duty_manager = (
            ui_setting?.duty_manager?.value?.[0]
            ? ui_setting.duty_manager.value[0]
            : partner?.manager
        );

        if (duty_manager?.email !== undefined && duty_manager?.email !== null)
        {
            setCurrentManager(duty_manager);
        }
    }, [settings, partner])

    return (
        <>
            {(
                (settings?.footer?.only_logged_in?.value === true && isLoggedIn) ||
                (settings?.footer?.always?.value === true)
            ) ? (
                <>
                <footer className="footer">
                    <div className="wrapper">
                        {!!name || !isLoggedIn ? (
                            <>
                                {isSpecial ? (
                                    <div className="contacts-wrapper justify-center">
                                        <TDXLogo />
                                    </div>
                                ) : (
                                    <>
                                        <div className="contacts-wrapper">
                                            <TDXLogo />
                                            <p className="footer-address body-1">
                                                <span>
                                                    223053, Минская область, Минский район, Боровлянский с/с, 103/3-7, в районе
                                                    д. Дроздово
                                                </span>
                                                <span>223053, Минский район, д. Боровляны, а/я 105</span>
                                            </p>
                                            <p className="footer-mob-contacts">
                                                <a className="body-1" href="tel:+375 (29) 667-79-27">
                                                    +375 (29) 667-79-27
                                                </a>
                                                <a className="body-1" href="tel:+375 (17) 512-07-50">
                                                    +375 (17) 512-07-50
                                                </a>
                                                <a className="body-1" href="tel:+375 (17) 512-07-51">
                                                    +375 (17) 512-07-51
                                                </a>
                                            </p>
                                            <div className="footer-E-contacts">
                                                <a className="body-1" href="mailto:info@tradeicsbel.by">
                                                    info@tradeicsbel.by
                                                </a>
                                                <TDXButton
                                                    size="small"
                                                    bg="gray"
                                                    startIcon={<PhoneIcon />}
                                                    onClick={(e) => targetMenu(e, 'manager')}>
                                                    Менеджер
                                                </TDXButton>
                                            </div>
                                            <div className="footer-social-media-contacts">
                                                <a href="https://www.facebook.com/tradeicsbelplus" target="_blank" rel="noreferrer">
                                                    <TDXButton size="small" bg="gray icon">
                                                        <FacebookIcon/>
                                                    </TDXButton>
                                                </a>
                                                <a href="https://t.me/tradeicsbel" target="_blank" rel="noreferrer">
                                                    <TDXButton size="small" bg="gray icon">
                                                        <TelegramIcon/>
                                                    </TDXButton>
                                                </a>
                                                <a href="https://www.linkedin.com/company/75095302" target="_blank" rel="noreferrer">
                                                    <TDXButton size="small" bg="gray icon">
                                                        <LinkedinIcon/>
                                                    </TDXButton>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="copyrights-and-policies">
                                            <p className="body-1">© 2001—{currentYear} TradeicsBel</p>
                                            <div className="policies-wrapper">
                                                <Link to="/page/privacy" className="body-1">
                                                    Политика конфиденциальности
                                                </Link>
                                                <Link to="/page/terms" className="body-1">
                                                    Пользовательское соглашение
                                                </Link>
                                                <Link to="/page/support" className="body-1">
                                                    Поддержка пользователей
                                                </Link>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ):(
                            <>
                                <div className="contacts-wrapper">
                                    <Skeleton variant="circular" width={100} height={100} />
                                    <Skeleton className="body-1" width={200} height={100}></Skeleton>
                                    <Skeleton className="body-1" width={200} height={100}></Skeleton>
                                    <Skeleton className="body-1" width={200} height={100}></Skeleton>
                                    <Skeleton className="body-1" width={200} height={100}></Skeleton>
                                </div>
                                <div className="copyrights-and-policies">
                                    <Skeleton width={'100%'} height={20} />
                                </div>
                            </>

                        )}
                    </div>

                    <Popper
                        id="footer-popper"
                        open={!!menu.anchorEl}
                        anchorEl={menu.anchorEl}
                        placement="bottom-end">
                        <ClickAwayListener onClickAway={(e) => targetMenu(e, 'all-popper')}>
                            <Paper elevation={3}>
                                {menu.type === 'manager' ? (
                                    <List>
                                        <ListItem>
                                            <UserIcon/>
                                            <span className="body-1 light">
                                                {current_manager?.name || 'Не определено'}
                                            </span>
                                        </ListItem>

                                        <ListItem>
                                            <PhoneIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    padding: '3px',
                                                }}
                                            />
                                            {current_manager?.phone ? (
                                                <a
                                                    className="body-1 light"
                                                    href={`tel:${current_manager.phone}`}>
                                                    {current_manager.phone}
                                                </a>
                                            ) : (
                                                <span className="body-1 light">Не определено</span>
                                            )}
                                        </ListItem>

                                        <ListItem>
                                            <MailIcon/>
                                            {current_manager?.email ? (
                                                <a
                                                    className="body-1 light"
                                                    href={`mailto:${current_manager.email}`}>
                                                    {current_manager.email}
                                                </a>
                                            ) : (
                                                <span className="body-1 light">Не определено</span>
                                            )}
                                        </ListItem>

                                        {current_manager?.skype && (
                                            <ListItem>
                                                <SkypeIcon
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'rgba(0, 0, 0, 0.3)'
                                                    }}
                                                />
                                                <a
                                                    className="body-1 light"
                                                    href={`skype:${current_manager.skype}`}>
                                                    {current_manager.skype}
                                                </a>
                                            </ListItem>
                                        )}

                                        {current_manager?.telegram && (
                                            <ListItem>
                                                <TelegramIcon
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'rgba(0, 0, 0, 0.3)'
                                                    }}
                                                />
                                                <a
                                                    className="body-1 light"
                                                    target={'_blank'}
                                                    href={`https://t.me/${current_manager.telegram}`} rel="noreferrer">
                                                    {current_manager.telegram}
                                                </a>
                                            </ListItem>
                                        )}

                                        {current_manager?.viber && (
                                            <ListItem>
                                                <ViberIcon
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        color: 'rgba(0, 0, 0, 0.3)'
                                                    }}
                                                />
                                                <a
                                                    className="body-1 light"
                                                    href={`viber://chat?number=${current_manager.viber}`}>
                                                    {current_manager.viber}
                                                </a>
                                            </ListItem>
                                        )}

                                    </List>
                                ) : (
                                    <List>
                                        <ListItem>
                                            <TDXButton size="large" bg="gray" onClick={openWindow}>
                                                Сменить контрагента
                                            </TDXButton>
                                        </ListItem>
                                        <ListItem>
                                            <TDXButton
                                                size="large"
                                                bg="transparent"
                                                startIcon={<Logout/>}
                                                onClick={logOutHandler}>
                                                Выйти из системы
                                            </TDXButton>
                                        </ListItem>
                                    </List>
                                )}
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                </footer>
                </>
            ) : ''}
            <Popups/>
        </>
    )
}

export default Footer
