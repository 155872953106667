import {requestFailed, requestFinished, requestStarted} from '../store/actions/RequestActions'

export const requestHelper = (dispatch: any, requestName: any, request: any) => {
    dispatch(requestStarted(requestName))

    request().then(
        () => {
            dispatch(requestFinished(requestName))
        },
        (error: any) => {
            console.log(error)
            if (error.message !== 'canceled') {
                dispatch(requestFailed({requestName, error}))
            }
        }
    )
}
