import {useEffect} from 'react'
import {getFAQ} from '../../store/actions/GeneralActions'
import {getProfile} from '../../store/actions/ProfileActions'
import {useAppDispatch, useAppSelector} from '../../shared/hooks/hooks'
import HeaderCategoriesMenu from './HeaderCategoriesMenu'
import HeaderPreMenu from './HeaderPreMenu'
import HeaderNotify from "./HeaderNotify";
import HeaderMenu from "./HeaderMenu";
import HeaderMobileMenu from "./HeaderMobileMenu";
import {TDXProfileBalance} from "../UI";
import { useWindowSize } from "../useWindowSize"
import utils_settings from "../../utils/settings";
import TDXLogo from "../UI/TDXLogo"

const Header = () => {
    const [windowWidth/*, windowHeight*/] = useWindowSize()

    const dispatch = useAppDispatch()
    const questions = useAppSelector(state => state.General.questions)
    const {isSpecial, isCustomPrice} = useAppSelector(state => state.Profile.profile)
    const {settings} = useAppSelector(state => state.General.common)

    useEffect(() => utils_settings.settings_init(settings, dispatch), [])

    useEffect(() => {
        dispatch<any>(getProfile())
        !questions.length && dispatch<any>(getFAQ())
    }, [])

    if (windowWidth > 1024) {
        return (
            <>
                <header className="header header-pc">
                    <HeaderPreMenu />
                    <div className="header-navigation wrapper">
                        <TDXLogo />
                        <HeaderMenu />
                        <div>
                        {!isSpecial ? <TDXProfileBalance /> : ''}
                        </div>
                    </div>
                    <HeaderNotify />
                </header>
                <HeaderCategoriesMenu />
            </>
        )
    } else {
        return (
            <>
                <header className="header header-mob">
                    <HeaderPreMenu />
                    <div className="header-navigation wrapper">
                        <HeaderMobileMenu />
                    </div>
                    <HeaderNotify />
                </header>
            </>
        )
    }
}

export default Header
